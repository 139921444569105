/** @jsx jsx */
import { jsx } from '@emotion/core';

const footerWrap = {
  backgroundColor: '#444',
  fontWeight: 700,
  '*': { color: '#fff' },
};

const innerWrap = {
  alignItems: 'center',
  display: 'flex',
  height: 60,
  justifyContent: 'space-between',
  margin: 'auto',
  maxWidth: 1360,
  padding: '16px 24px',
};

export default function Footer() {
  return (
    <footer css={footerWrap}>
      <div css={innerWrap}>
        <p>Me</p>
        <p>You</p>
      </div>
    </footer>
  );
}
