/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, Fragment } from 'react';
import Faker from 'faker';
import { Card, Button } from '@material-ui/core';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

export default function Classifieds() {
  return (
    <div>
      <h1>Classifieds</h1>
      <div
        css={{
          marginTop: 24,
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
          gridColumnGap: '16px',
          // gridTemplateRows: '250px',
          gridRowGap: '24px',
        }}
      >
        <CardFoo />
      </div>
    </div>
  );
}

class CardFoo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  componentWillMount() {
    for (let i = 0; i < 25; i++) {
      const user = {
        name: Faker.internet.userName(),
        productName: Faker.commerce.productName(),
        product: Faker.commerce.product(),
        department: Faker.commerce.department(),
        price: Faker.commerce.price(),
        image: Faker.image.cats(),
      };
      this.setState((prevState) => ({
        users: [...prevState.users, user],
      }));
    }
  }

  renderUsers(user) {
    return (
      <Card>
        <CardActionArea>
          <CardMedia className='card-image' image={user.image} title='Contemplative Reptile' />
          <CardContent>
            <Typography gutterBottom variant='h5' component='h4'>
              {user.productName}
            </Typography>
            <Typography variant='body2' color='textSecondary' component='p'>
              {user.product}
            </Typography>
            <Typography variant='body1' color='textPrimary' component='p'>
              ${user.price}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size='small' color='primary'>
            Contact Seller
          </Button>
          <Button size='small' color='primary'>
            More Details
          </Button>
        </CardActions>
      </Card>
    );
  }

  render() {
    return <Fragment>{this.state.users.map((user) => this.renderUsers(user))}</Fragment>;
  }
}
