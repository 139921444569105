/** @jsx jsx */
import { jsx } from '@emotion/core';

export default function Discuss() {
  return (
    <div>
      <h1>Discuss</h1>
    </div>
  );
}
