/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Helmet } from 'react-helmet';

const root = {
  padding: '24px 0',
  textAlign: 'center',
  color: 'white',
  p: {
    fontSize: 18,
  },
};

export default function Home() {
  return (
    <div css={root}>
      <Helmet>
        <title>Tzvi Newman</title>
        <meta name='description' content='Everything Tzvi Newman' />
      </Helmet>
      <div css={{ backgroundColor: 'rgba(0, 0, 0, 0.4)', padding: 16, display: 'inline-block', borderRadius: 6 }}>
        <p>
          This Site is intended to be an all encompassing source of information and data, another google if you will.
        </p>
        <p className='mt2'>My company was established on September 17, 1987</p>
      </div>
    </div>
  );
}
