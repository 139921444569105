import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Layout from './Layout';
import Home from './pages/Home';
import Discuss from './pages/Discuss';
import Deals from './pages/Deals';
import Classifieds from './pages/Classifieds';

function App() {
  return (
    <Router>
      <Layout>
        <Route exact path='/' component={Home} />
        <Route exact path='/classifieds' component={Classifieds} />
        <Route exact path='/discuss' component={Discuss} />
        <Route exact path='/deals' component={Deals} />
      </Layout>
    </Router>
  );
}

export default App;
