/** @jsx jsx */
import { jsx } from '@emotion/core';

export default function Deals() {
  return (
    <div>
      <h1>Deals</h1>
    </div>
  );
}
