/** @jsx jsx */
import { jsx } from '@emotion/core';
import Header from './ui/Header';
import Footer from './ui/Footer';

const layoutWrap = {
  padding: '16px 20px',
  maxWidth: 1360,
  margin: 'auto',
  minHeight: 'calc(100vh - 120px)',
};

export default function Layout({ children }) {
  return (
    <div>
      <Header />
      <div css={layoutWrap}>{children}</div>
      <Footer />
    </div>
  );
}
