/** @jsx jsx */
import { jsx } from '@emotion/core';
import { NavLink } from 'react-router-dom';
// import { Layout, Menu, Breadcrumb } from 'antd';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const headerWrap = {
  backgroundColor: '#444',
  // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  fontWeight: 700,
  '*': { color: '#fff' },
  a: {
    padding: 8,
    borderRadius: 4,
    transition: 'all .2s',
    '&:hover': {
      background: '#e96443',
      transition: 'all .2s',
    },
  },
};

const innerWrap = {
  alignItems: 'center',
  display: 'flex',
  height: 60,
  justifyContent: 'space-between',
  margin: 'auto',
  maxWidth: 1360,
  width: '100%',
};

export default function Header() {
  return (
    <header css={headerWrap}>
      <AppBar position='static'>
        <Toolbar>
          <div css={innerWrap}>
            <NavLink to='/'>Home</NavLink>
            <NavLink to='/classifieds'>Classifieds</NavLink>
            <NavLink to='/discuss'>Discuss</NavLink>
            <NavLink to='/deals'>Deals</NavLink>
            <IconButton edge='start' color='inherit' aria-label='menu'>
              <MenuIcon color='primary' />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </header>
  );
}
